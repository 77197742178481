<template>
  <el-form
    :model="infoForm"
    :rules="infoRules"
    ref="infoForm"
    label-width="200px"
    class="info-form"
  >
    <el-form-item label="公司名称">{{infoForm.customername}}</el-form-item>
    <el-form-item
      v-if="infoForm.servicemanagerid"
      label="专属客服经理"
    >{{serviceManager.customername}} -- {{serviceManager.username}}({{serviceManager.mobilephone}})</el-form-item>
    <el-form-item label="当前用户">{{username}}</el-form-item>
    <el-form-item label="登录帐号">{{mobilephone}}</el-form-item>
    <el-form-item prop="area" label="公司地址">
      <el-cascader
        :options="cityOptions"
        v-model="infoForm.area"
        @change="cascaderChange"
        placeholder="省/市/区"
      ></el-cascader>
    </el-form-item>
    <el-form-item prop="address" label="详细地址">
      <el-input
        type="textarea"
        v-model="infoForm.address"
        :rows="3"
        auto-complete="off"
        placeholder="详细地址"
      ></el-input>
    </el-form-item>
    <el-form-item prop="contact" label="联系方式">
      <el-input type="text" v-model="infoForm.contact" auto-complete="off" placeholder="联系方式"></el-input>
    </el-form-item>
    <el-form-item prop="username" label="修改密码">
      <el-input type="password" v-model="infoForm.password" auto-complete="off" placeholder="修改密码"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        class="info-button"
        @click.native.prevent="saveChange('infoForm')"
        :loading="loading"
      >保存修改</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import NProgress from "nprogress";
import { mapGetters, mapActions } from "vuex";
import { citys } from "../common/city.js";
export default {
  data() {
    return {
      infoForm: {
        area: [],
        customername: "",
        address: "",
        contact: "",
        password: "",
        servicemanagerid: null
      },
      serviceManager: {},
      infoRules: {},
      loading: false,
      cityOptions: citys
    };
  },
  computed: {
    ...mapGetters(["username", "mobilephone"])
  },
  methods: {
    ...mapActions(["LogOut"]),
    cascaderChange() {
      // console.log(this.infoForm.area)
    },
    getCustomerInfo() {
      this.axios.post("/user/getcustomerinfo").then(reply => {
        Object.assign(this.infoForm, reply.data.values);
        if (this.infoForm.servicemanagerid != null) {
          this.axios
            .post("/usermanagement/getuser", {
              userid: this.infoForm.servicemanagerid
            })
            .then(res => {
              this.serviceManager = res.data.values;
            });
        }
      });
    },
    saveChange() {
      this.loading = true;
      NProgress.start();
      let params = {};
      Object.assign(params, this.infoForm);
      params.area = this.infoForm.area.join(",");
      this.axios.post("/user/updateCustomerinfo", params).then(reply => {
        if (reply.data.code === 0) {
          this.$notify({
            offset: 45,
            title: "成功",
            message: "保存成功",
            type: "success"
          });
        } else {
          this.$notify({
            offset: 45,
            title: "失败",
            message: "保存失败",
            type: "error"
          });
          this.getCustomerInfo();
        }
        this.infoForm.password = this.infoForm.password.trim();
        if (this.infoForm.password !== "") {
          // 重置密码
          this.axios
            .post("/user/updatepassword", {
              password: this.infoForm.password
            })
            .then(reply => {
              // 密码修改成功则强制登出重新登录
              this.LogOut().then(() => {
                this.$router.push("/login");
              });
            });
        }
        this.loading = false;
        NProgress.done();
      });
    }
  },
  mounted() {
    this.getCustomerInfo();
  }
};
</script>
<style scoped lang="scss">
.info-form {
  margin-top: 30px;
  max-width: 600px;
  .el-cascader {
    width: 400px !important;
  }
}
</style>